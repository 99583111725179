import React from "react";

// Stylesheets
import "./About.scss";

// Images
import image from "../../../images/photos/madart.jpg";

const About = () => {
    return (
        <section className="about">
            <div id="over-ons" className="scroll-point" />
            <div className="container">
                <div className="info">
                    <h1>Wie zijn wij?</h1>
                    <div className="text">
                        <p>Wij zijn Justin en Rafael, twee jonge
galeriehouders met een voorliefde voor
film en fashion. Onze passie voor kunst
bracht ons bijeen en de eerste samenwerking was meteen een schot in de
roos: de verfilming van poëzie.</p>
                        <p>De synergie tussen videografie en
poëzie heeft ons geïnspireerd om deze
samenwerking voort te zetten, maar dan
met andere kunstenaars. Zo ontstond er
een unieke combinatie van verschillende
disciplines die elkaar versterken en
verrassende resultaten opleveren.</p>
                        <p>Wij zijn meer dan alleen tussenpersonen
tussen kunstenaars en publiek. We
werken nauw samen met de kunstenaars om hun visie tot leven te brengen
en weten als geen ander hoe belangrijk
het is om een creatie op de juiste
manier te presenteren.</p>
                    </div>
                </div>
                <div className="image">
                    <img src={image} alt="" />
                </div>
            </div>
        </section>
    );
};

export default About;