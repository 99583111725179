import React from "react";

// Stylesheets
import "./Concept.scss";

// Images
import concept_yvonne from "../../../images/photos/concept-yvonne.png";
import concept_tess from "../../../images/photos/concept-tess.jpeg";
import concept_giulio from "../../../images/photos/concept-giulio.jpeg";
import concept_chaouki from "../../../images/photos/concept-chaouki.jpeg";
import yvonne from "../../../images/photos/yvonne.jpeg";
import tess from "../../../images/photos/tess.jpeg";
// import giulio from "../../../images/photos/giulio.tiff";
// import chaouki from "../../../images/photos/chaouki.jpeg";

// Icons
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Concept = () => {
    return (
        <section className="concept">
            <div id="concept" className="scroll-point" />
            <div className="info">
                <h1>Vier Verhalen, Vier Exposities</h1>
                <div className="text">
                    <p>Bij Madart Galleries draait alles om verhalen – de verhalen van kunstenaars en hun werken, maar ook hoe deze werken hun weg naar ons vinden. De komende vier exposities weerspiegelen bijzondere ontmoetingen en onverwachte wendingen die onze galerie blijven verrassen en inspireren.</p>
                    <p><b>Yvonne Vel: In Memoriam (26 - 30 oktober)</b><br />
                    De eerste expositie is een eerbetoon aan de mysterieuze en getalenteerde Yvonne Vel, twintig jaar na haar overlijden. Het was een groot raadsel hoe een van haar werken in het Vondelpark opdook, maar dat moment bracht ons in contact met haar enige erfgenaam. Haar oeuvre, dat sindsdien in de schaduw heeft gestaan, komt nu opnieuw tot leven. Deze expositie is een bijzondere kans om stil te staan bij haar invloedrijke werk en haar artistieke nalatenschap te herdenken.</p>
                    <img src={concept_yvonne} alt="Uitnodiging Yvonne Vel" />
                    <p><b>Tess Heijnis: Een Spontane Ontmoeting (3 - 6 oktober)</b><br />
                    Soms brengt het leven je op de meest onverwachte plekken. Dat was zeker het geval met Tess Heijnis, die – vermoeid, bedekt met verf en met een volle blaas – toevallig langs Madart Galleries reed na twee uur in de file te hebben gestaan. Ondanks dat ze naar huis wilde voelde ze ineens dat ze bij ons naar binnen moest stappen en gelukkig deed ze dat.<br />
                    Tess was ervan overtuigd dat wij kunstenaars waren en wilde graag met ons praten over haar werk en onze gedeelde passie voor schilderkunst en ons uitnodigen voor haar eigen expositie. 
                    En dit spontane bezoek leidde tot haar aanstaande expositie.</p>
                    <img src={concept_tess} alt="Uitnodiging Tess Heijnis" />
                    <p><b>Giulio Zahnd: Een Multi-talent Ontdekt (10 - 14 oktober)</b><br />
                    Onze ontmoeting met Giulio Zahnd was even verrassend als zijn veelzijdigheid. Wat begon als een kennismaking met zijn fotografie, bleek het begin van een ontdekkingstocht door zijn vele talenten. Niet alleen maakt hij prachtige beelden, maar Giulio blijkt ook een virtuoos muzikant met absoluut gehoor, en ontwerpt hij kleding met een geheel eigenzinnige stijl. Zijn debuutexpositie bij ons is een unieke kans om een glimp op te vangen van deze veelvoud aan artistieke expressies.</p>
                    <img src={concept_giulio} alt="Uitnodiging Giulio Zahnd" />
                    <p><b>Chaouki Dalal: Een Verhaal van Familietrots (17 - 31 oktober)</b><br />
                    De laatste expositie in deze reeks komt voort uit een intiem familieverhaal. Enkele weken geleden kwam de zoon van Chaouki Dalal bij ons langs om te vertellen over zijn vader, een gerenommeerde aquarellist uit Libanon. Zijn werk, doordrenkt met kleur en emotie, weerspiegelt een diepe verbondenheid met zijn vaderland en zijn persoonlijke geschiedenis. Deze expositie biedt een unieke kijk op de rijke traditie van aquarelkunst, met een focus op de Levant.</p>
                    <img src={concept_chaouki} alt="Uitnodiging Chaouki Dalal" />
                    <p>Bij Madart Galleries geloven we dat kunst en verhalen onlosmakelijk met elkaar verbonden zijn. We nodigen je van harte uit om deze vier bijzondere exposities te komen beleven en mee te gaan in de boeiende verhalen achter elk van deze getalenteerde kunstenaars.</p>
                    <p className="more-link"><a href="#artiesten">Zie artiesten <ArrowDownwardIcon /></a></p>
                </div>
            </div>
            <div className="more">
                <div id="artiesten" className="scroll-point" />
                <div className="artists">
                    <div className="artist yvonne">
                        <h1>Yvonne Vel</h1>
                        <img src={yvonne} alt="Yvonne Vel" />
                        <p>Yvonne Vel (1944-2004) studeerde aan de Kunstacademie in Arnhem voordat zij werd ontdekt als fotomodel en werd o.a. het gezicht van de Frans Molenaar wintercollectie 1965-1966. In 1967 was zij één van de inzittenden tijdens het fatale auto-ongeluk van fotograaf Sanne Sannes (1937-1967), waarmee haar modellencarrière in één klap voorbij was. Oorspronkelijk bedoeld als therapie tijdens haar revalidatie heeft zij het schilderen opnieuw opgepakt en ontwikkelde Yvonne zich als een veelbelovend en graag gezien kunstenares. Op initiatief van Justin Karijoredjo en Rafael Porto le Blanche van Madart Galleries en de erven van Yvonne worden nu, tijdens haar 20ste sterfjaar, enkele van haar beste werken opnieuw geëxposeerd en te koop aangeboden. De werken representeren haar meest succesvolle periode als kunstenares in de jaren '80 van de vorige eeuw en kenmerken zich door abstracte vergezichten van zanderige landschappen in gemengde technieken. De opening van de expositie vindt plaats op 26 september 2024 in Madart Galleries aan de Alexander Boersstraat 33 te Amsterdam om 17:00 uur en zal duren van 27 tot en met 30 september 2024.</p>
                        <div className="border" />
                    </div>
                    <div className="artist tess">
                        <h1>Tess Heijnis</h1>
                        <img src={tess} alt="Tess Heijnis" />
                        <p>Intuïtief schilderen betekent voor Tess communiceren met verf, kleuren en materialen in plaats van met woorden. Ze observeert wat er gebeurt wanneer ze haar hoofd leegmaakt en haar handen laat doen wat ze willen. Dit is wat het maken van abstracte kunst Tess brengt, ze moet écht'mindful' zijn om zichzelf weg te houden van de mind. Ze geniet ervan om te experimenteren en gewoon te kijken wat er gebeurt, zonder een vast doel en zonder regels en beperkingen. Het blijft voor haar een fijn uitstapje van onze maatschappij waarin alles zo gepland lijkt te zijn en waar we vaak te horen krijgen wat we 'moeten' doen…</p>
                        {/* <div className="border" /> */}
                    </div>
                    {/* <div className="artist giulio">
                        <h1>Giulio Zahnd</h1>
                        <img src={giulio} alt="Giulio Zahnd" />
                        <p>Geboren en getogen in Zwitserland, werd ik in mijn jeugd ondergedompeld in muziek. Ik begon piano te spelen toen ik vijf was en onderzocht andere instrumenten toen ik opgroeide, maar naarmate ik ouder werd, zocht ik een ander medium om mezelf volledig uit te drukken. Hoewel ik op mijn twaalfde voor het eerst in aanraking kwam met fotografie via analoge camera's, besefte ik pas in 2020 dat het mijn ware passie was. De afgelopen vier jaar heb ik me toegelegd op het beheersen van licht, compositie en vorm. Rond dezelfde tijd ontwikkelde ik een diepe band met techno, waardoor ik me ging verdiepen in queerness en sex-positivity - twee concepten die me hebben geholpen om zowel mijn identiteit als mijn leven te definiëren. Van bunkers tot clubs, van bergen tot bosraves, ik begon een reis van zelfonderzoek in een wereld die al als thuis voelde. Nadat ik naar Nederland was verhuisd om modeontwerp te studeren, begon ik deze werelden samen te voegen - ik gebruikte het lichaam als een architectonisch instrument om het expressieve en structurele potentieel ervan te verkennen. Nu, na een jaar Amsterdam [mijn] thuis te hebben genoemd, ben ik verheugd je uit te nodigen voor mijn eerste solotentoonstelling, waar deze persoonlijke verkenningen tot leven komen.</p>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default Concept;