import React from "react";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Stylesheets
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Slider.scss";

// Images
import slide1 from "../../../images/photos/slide1.jpg";
import slide2 from "../../../images/photos/slide2.jpg";
import slide3 from "../../../images/photos/slide3.jpg";
import slide4 from "../../../images/photos/slide4.jpg";
import slide5 from "../../../images/photos/slide5.jpg";
import slide6 from "../../../images/photos/slide6.jpg";

// Icons
import KeyboardArrowDownLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownRight from "@mui/icons-material/KeyboardArrowRight";

const Slider = () => {
    return (
        <section className="slider" id="slider">
            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 85,
                    modifier: 10
                }}
                pagination={{ el: ".swiper-pagination", clickable: true }}
                navigation={{
                    nextEl: ".next",
                    prevEl: ".previous",
                    clickable: true
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper-container"
            >
                <SwiperSlide><img src={slide1} alt="slide_image" /></SwiperSlide>
                <SwiperSlide><img src={slide2} alt="slide_image" /></SwiperSlide>
                <SwiperSlide><img src={slide3} alt="slide_image" /></SwiperSlide>
                <SwiperSlide><img src={slide4} alt="slide_image" /></SwiperSlide>
                <SwiperSlide><img src={slide5} alt="slide_image" /></SwiperSlide>
                <SwiperSlide><img src={slide6} alt="slide_image" /></SwiperSlide>

                <div className="controller">
                    <div className="previous arrow">
                        <KeyboardArrowDownLeft />
                    </div>
                    <div className="next arrow">
                        <KeyboardArrowDownRight />
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </Swiper>
        </section>
    );
};

export default Slider;