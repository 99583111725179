import React from "react";

// Stylesheets
import "./App.css";

// Routes
import Home from "./routes/home/Home";

const App = () => {
    return (
        <Home />
    );
};

export default App;