import React, { useEffect } from "react";

// Videos
import top_video from "../../../images/videos/top-video.mp4";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Stylesheets
import "./Top.scss";

// Constants
const VIDEO_TIMEOUT = 1500;

const Top = () => {
    useEffect(() => {
        const video = document.getElementsByTagName("video")[0];
        
        setTimeout(() => {
            video.play();            
        }, VIDEO_TIMEOUT);
    }, []);

    return (
        <section className="top" id="top">
            <div className="event centered">
                <h1>MADART GALLERIES</h1>
                <p>OKTOBER 2024</p>
                <span className="artiesten-button">
                    <a href="#artiesten">
                        <button><span>ZIE ARTIESTEN</span></button>
                    </a>
                </span>
            </div>
            <video
                playsInline 
                src={top_video}
                muted
                loop
            ></video>
            <a href="#over-ons" className="scroll-down smooth-scroll">
                <KeyboardArrowDownIcon className="centered" />
            </a>
        </section>
    );
};

export default Top;