import React from "react";

// Stylesheets
import "./Gallery.scss";

// Images
import gallery1_small from "../../../images/photos/gallery1-small.png";
import gallery2_small from "../../../images/photos/gallery2-small.png";
import gallery3_small from "../../../images/photos/gallery3-small.png";
import gallery4_small from "../../../images/photos/gallery4-small.png";

const Gallery = () => {

    return (<>
        <section className="gallery"> 
            <div id="locatie" className="scroll-point" />
            <div className="image image1">
                <img src={gallery1_small} alt="" />
            </div>
            <div className="image image2">
                <img src={gallery2_small} alt="" />
            </div>
            <div className="image image3">
                <img src={gallery3_small} alt="" />
            </div>
            <div className="image image4">
                <img src={gallery4_small} alt="" />
            </div>
        </section>
    </>);
};

export default Gallery;