import React, { useEffect, useState } from "react";
import PageLoader from "../../components/home/pageloader/PageLoader";
import NavBar from "../../components/home/navbar/NavBar";
import Top from "../../components/home/top/Top";
import About from "../../components/home/about/About";
import Slider from "../../components/home/slider/Slider";
import Concept from "../../components/home/concept/Concept";
import Gallery from "../../components/home/gallery/Gallery";
import Contact from "../../components/home/contact/Contact";
import "./Home.scss"

// Constants
const PAGE_LOADER = 0;

const Home = () => {
    const [loading, setLoading] = useState(true);

    const links = {
        "#over-ons": "OVER ONS",
        "#concept": "CONCEPT",
        "#contact": "CONTACT",
    }

    useEffect(() => {
        const loadingTimeout = setTimeout(() => {
            setLoading(false);
        }, PAGE_LOADER);

        return () => {
            clearTimeout(loadingTimeout);
        };
    }, []);

    return (
        <div className="home">
            {loading && <PageLoader />}

            <NavBar links={links} navScroll={true} />
            <Top />
            <About />
            <Slider />
            <Concept />
            <Gallery />
            <Contact />
        </div>
    );
};

export default Home;